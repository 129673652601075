import Header from '../../components/Header';

const AntiMoneyLaunderingPolicy = () => {
  return (
    <>
      <Header
        title="Anti-Money Laundering Policy"
      />
      <div className="mt-5 container text-center">
      <h6>
        Money Laundering and Terrorist Financing is the process in which assets obtained or generated through criminal activities are moved or concealed to obscure their link with the crime. Perpetrators of the crime find ways to launder the funds in order to use them without drawing the attention of the applicable authorities. Money Laundering empowers corruption and organized crime where corrupt public officials and criminals can launder proceeds from crimes, bribes, kickbacks, public funds, and on some occasion, even development loans from international financial institutions. Organized criminal groups want to be able to launder the proceeds of drug trafficking and commodity smuggling through the financial systems without a trace. In the modern-day definition, Money Laundering now covers various predicate offences including child trafficking, prostitution, etc.
        The degree of sophistication and complexity in the money laundering scheme is infinite and is limited only by the creative imagination and expertise of criminals. Terrorist activities are sometimes funded from the proceeds of illegal activities. Although often linked in legislation and regulation, terrorist financing and money laundering are conceptual opposites. Money laundering is the process where cash raised from criminal activities is made to look legitimate for re-integration into the financial system, whereas terrorist financing cares little about the source of the funds, but it is what the funds are to be used for that defines its scope.
        In recent years, the international community has become more aware of the dangers that money laundering and terrorist financing poses in all these areas, and many governments and jurisdictions have committed themselves to acting. The United Nations and the other international organizations like Financial Action Task Force (FATF) are committed to helping governments in any way they can.
        OBIC TRADE is committed to fighting money laundering and complying fully with anti-money laundering laws in Nigeria. We understand that we have responsibilities to help fight the global battle against money laundering and our commitment will supersede all other privacy obligations contained in our policies. Accordingly, Obic trade will take all reasonable and appropriate steps to prevent persons engaged in money laundering, fraud, or other financial crimes from utilizing our products and services. Our AML policies, procedures, and internal controls are designed to ensure compliance with all applicable BSA regulations and FINRA rules and will be reviewed and updated on a regular basis to ensure appropriate policies, procedures, and internal controls are in place to account for both changes in regulations and changes in our business.
      </h6><br /><br />
      <b>Scope Of Our AML/CFT Policy</b><br />
      <h6>
        We adopt a risk-based approach to mitigating the Money Laundering and Terrorism Financing (ML/TF) risks to ensure Obic trade is adequately protected from being used as a conduit or facilitator of ML/TF. As a mode of enforcement, the company reserves the right to take disciplinary action for non-compliant employees or contractors as provisions set out in this Policy must always be complied with.
        The three major areas within the scope of Compliance are:
        <ol>
          <li>Organizational related risk: This includes risk in relation to non-compliance with organizational conflicts of interest rules, market abuse rules and insider trading rules, intermediary risks, business partner risks, non-licensed business activities risk etc.</li>
          <li>Financial Services conduct related risk: This includes addressing risks in relation to potential noncompliance with regulatory requirements of both local and international financial services regulators, as well as any other relevant laws and regulations, internal company or business rules and policies, and relevant principles and values. Risks in relation to relationship with regulators.</li>
          <li>Client-related, or Financial & Economic Crime risk: This includes risks in relation to potential noncompliance with Money Laundering and Terrorist Financing laws, antifraud regulations, and regulations in relation to politically and financially exposed persons. Regulations in relation to import and export control regulations, transactions through sanctioned countries etc.</li>
        </ol>
        There is an independent AML/CTF Compliance function at Obic trade which shall be executed and managed by a designated Compliance Officer, who will regularly update management and board of directors on all material issues.
        The AML/CTF Policy encompasses, but is not limited to the following activities:
        <ul>
          <li>(a) Know Your Customer (identification requirements),</li>
          <li>(b) Customer Due Diligence (verification procedures),</li>
          <li>(c) Risk identification and assessment - defining the scope of eligible and not eligible activities,</li>
          <li>(d) Transactional monitoring,</li>
          <li>(e) PEP and Sanctions screening,</li>
          <li>(f) Record keeping, training and other pertinent aspects, as required by applicable laws and regulations.</li>
        </ul>
      </h6><br /><br />
      <b>Know Your Customer Procedures</b><br />
      <h6>
        Establishing and maintaining a risk-based approach to Customer Due Diligence (CDD), including customer identification, verification and KYC procedures. To ensure we meet these standards, our customers are required to provide certain personal details when opening a OBIC TRADE account and generate tokens, OTP, and PIN for any withdrawal request. The nature, and extent, of what is required is guided by the customer’s deposit and withdrawal limits, account to be used and in some cases, the customer’s country of residence.
        In certain circumstances, OBIC TRADE may perform enhanced due diligence procedures for customers presenting a higher risk, such as those transacting large volumes etc. Maintaining appropriate KYC records for the minimum prescribed periods.
        OBIC TRADE shall ensure timely and accurate rendition of all AML/CFT returns as specified in the SEC AML/CFT Rules and Regulations as well as other relevant Regulations/Act/Guidelines/Circulars that may be issued from time to time by various government agencies. We shall exercise due diligence in identifying and reporting a suspicious transaction.
        Suspicious transactions shall include:
        <ul>
          <li>Transactions which are structured to avoid reporting and record keeping requirements.</li>
          <li>Altered or false identification or inconsistent information or any transaction involving criminal activity in OBIC TRADE’s view.</li>
          <li>Entity that belongs to a person or organization considered as terrorist.</li>
        </ul>
      </h6><br /><br />
      <b>Internal control</b><br />
      <h6>
        Obic trade has formulated and implemented internal controls and other procedures that will deter criminals from using its facilities for money laundering and terrorist financing and to ensure that its obligations under subsisting laws and regulations are met.
      </h6><br /><br />
      <b>Risk Assessment</b><br />
      <h6>
        Obic trade identifies and assesses the money laundering and terrorism financing risks that may be associated with its unique business, services, and customers. More specifically, Obic trade shall carry out a business-wide ML/TF risk assessment on a yearly basis, assessing the risks to which Obic trade is exposed as a result of the nature and complexity of its business; and assess the risks to which Obic trade is exposed as a result of entering a business relationship or carrying out a particular transaction, on a continuous basis.
        Each risk assessment will consist of two separate, but related steps which are identification of the ML/TF risk factors, and assessment of any such factors.
        Obic trade performs its risk assessment prior to launching new products or services, and continuously reviews and updates its approach to risk management.
      </h6><br /><br />
      <b>Compliance with Laws and Regulations</b><br />
      <h6>
        ObicTrade adheres to all applicable AML/CTF laws, regulations, and standards, including:
        <ul>
          <li>Financial Action Task Force (FATF) Recommendations.</li>
          <li>Nigerian AML laws and other relevant local and international guidelines.</li>
        </ul>
      </h6><br /><br />
      <b>Employee Training and Customer Awareness</b><br />
      <h6>
        <b>Employee Training</b><br />
        We provide regular AML/CTF training to all employees, ensuring they:
        <ul>
          <li>Understand ML/TF risks and compliance requirements.</li>
          <li>Stay updated on emerging trends, regulations, and enforcement practices.</li>
        </ul>
        <b>Customer Awareness</b><br />
        We educate our customers on AML/CTF policies through:
        <ul>
          <li>Newsletters, in-app notifications, and online guides.</li>
          <li>Ensuring transparency in our operations and requirements.</li>
        </ul>
      </h6><br /><br />
      <b>Record Keeping and Reporting</b><br />
      <h6>
        We maintain comprehensive records of:
        <ul>
          <li>Customer information and verification data.</li>
          <li>Transactional activity and due diligence procedures.</li>
          <li>Suspicious activity reports (SARs) submitted to authorities.</li>
        </ul>
        These records are retained for the minimum periods required by law or as necessary to comply with ongoing investigations or legal obligations.
      </h6><br /><br />
      <b>Enforcement and Accountability</b><br />
      <h6>
        We enforce our AML policies rigorously. Violations of our AML policies can result in:
        <ul>
          <li>Suspension or termination of accounts.</li>
          <li>Reporting to regulatory authorities or law enforcement.</li>
        </ul>
        We are committed to ensuring compliance and accountability in the prevention of financial crimes.
      </h6><br /><br />
      </div>
    </>
  );
};

export default AntiMoneyLaunderingPolicy;
