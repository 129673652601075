import React from 'react';

const Payment = () => {
  const paymentItems = [
    {
      title: 'Fast Payouts',
      description:
        'Receive payments instantly or within minutes, ensuring a hassle-free experience.',
      icon: '⚡', // Placeholder for an icon
    },
    {
      title: 'Multiple Payment Options',
      description:
        'Choose from a variety of payment methods that work best for you, including bank transfers, mobile wallets, and cryptocurrency payouts.',
      icon: '💳',
    },
    {
      title: 'Transparent Processes',
      description: 'No hidden fees or surprises—what you see is what you get.',
      icon: '💬',
    },
    {
      title: 'Global Reach',
      description:
        'Our payment systems are designed to accommodate users worldwide, ensuring accessibility no matter where you are.',
      icon: '🌍',
    },
  ];

  return (
    <section
      id="payment"
      style={{
        background: 'linear-gradient(135deg, #6a11cb, #2575fc)',
        color: '#fff',
        padding: '5rem 0',
      }}
    >
      <div className="container text-center">
        <h2
          style={{
            fontSize: '3rem',
            fontWeight: 'bold',
            letterSpacing: '2px',
            marginBottom: '1.5rem',
          }}
        >
          Payment
        </h2>
        <p
          style={{
            fontSize: '1.2rem',
            lineHeight: '1.8',
            maxWidth: '800px',
            margin: '0 auto',
            fontWeight: 'lighter',
          }}
        >
          At ObicTrade, we believe in making payments simple, secure, and flexible.
        </p>
        <div className="row mt-5 p-6">
          {paymentItems.map((item, index) => (
            <div
              key={index}
              className="col-lg-3 col-md-6 col-sm-12 mb-4"
              style={{
                padding: '2rem',
                backgroundColor: '#fff',
                color: '#333',
                borderRadius: '12px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                textAlign: 'center',
                transform: 'scale(1)',
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.transform = 'scale(1.05)';
                e.currentTarget.style.boxShadow = '0 8px 16px rgba(0, 0, 0, 0.2)';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.transform = 'scale(1)';
                e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.1)';
              }}
            >
              <div
                style={{
                  fontSize: '3rem',
                  color: '#2575fc',
                  marginBottom: '1rem',
                }}
              >
                {item.icon}
              </div>
              <h4
                style={{
                  fontSize: '1.5rem',
                  fontWeight: 'bold',
                  marginBottom: '1rem',
                }}
              >
                {item.title}
              </h4>
              <p
                style={{
                  fontSize: '1rem',
                  fontWeight: 'lighter',
                  color: '#555',
                  lineHeight: '1.6',
                }}
              >
                {item.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Payment;
