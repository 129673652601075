import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import React from "react";
import { FaCheckCircle } from "react-icons/fa";

const InternationalLine = () => {
    return (
        <section className="container mx-auto px-6 py-12 lg:py-20">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
        <div className="flex flex-col items-center lg:items-start text-left lg:text-left p-4">
           
                <h2 className="obic-title">Top Up Your International Lines</h2>
                <p className="obic-description">
                    Stay connected globally with ObicTrade! Whether you're traveling or keeping in touch with loved ones abroad, our international line top-up service ensures you’re always in touch.
                </p>
                <ul className="obic-features">
                    <li>
                        <FaCheckCircle className="feature-icon" />
                        Reach Out on WhatsApp: Tell us the country and network provider.
                    </li>
                    <li>
                        <FaCheckCircle className="feature-icon" />
                        Confirm Payment: Share the phone number you’d like to top up and make a secure payment.
                    </li>
                    <li>
                        <FaCheckCircle className="feature-icon" />
                        Instant Top-Up: Enjoy uninterrupted communication as your line is credited immediately.
                    </li>
                </ul>
                <a href="#learn-more" className="obic-cta">
                    Learn more →
                </a>
            </div>
            <div className="obic-image">
                <DotLottieReact
                    src="https://lottie.host/d82225a9-5357-4ee4-ac8d-d13356664cdf/LmIZl0ZbJC.json"
                    loop
                    autoplay
                />
            </div>

            </div>
        </section>
    );
};

export default InternationalLine;
