import { motion } from 'framer-motion';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';

function Hero() {
  return (
    <motion.section
      className="section-container bg-gradient-to-r from-[rgb(12,28,60)] via-[rgb(22,49,97)] to-[rgb(34,64,120)] text-white"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6, ease: "easeOut" }}
    >
      <section className="flex items-center justify-center min-h-screen">
        <div className="container mx-auto px-1 lg:px-12">
          <div className="grid grid-cols-1 lg:grid-cols-2 items-center gap-10">
            
            {/* Left Section: Text Content */}
            <div className="space-y-6 mt-5">
              <h1 className="text-4xl lg:text-6xl font-bold leading-tight">
                Buy and Sell Gift Cards <br />
                <span className="text-orange-500">Instantly</span>
              </h1>
              <p className="text-lg lg:text-xl font-light text-gray-300">
                Join millions globally to trade digital assets seamlessly and receive instant cash for your gift cards.
              </p>
              <div className="flex gap-4">
                <a
                  href="https://wa.me/message/LOVDCRGL47Q5P1"
                  className="px-6 py-3 text-lg font-semibold rounded bg-orange-500 text-white no-underline hover:bg-orange-600 shadow-lg hover:scale-105 transition-transform border-2 border-orange-700"
                >
                  Get Started
                </a>
                <a
                  href="/about"
                  className="px-6 py-3 text-lg font-semibold rounded bg-gray-800 text-white no-underline hover:bg-gray-700 shadow-lg hover:scale-105 transition-transform border-2 border-gray-600"
                >
                  Learn More
                </a>
              </div>
            </div>

            {/* Right Section: Lottie Animation */}
            <div className="flex justify-center">
              <DotLottieReact
                src="/lottie/giftcard.json"
                loop
                autoplay
              />
            </div>

          </div>
        </div>
      </section>
    </motion.section>
  );
}

export default Hero;
