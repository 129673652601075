import React from 'react';

const BuyGlobalESIMs = () => {
  return (
    <section className="bg-gradient-to-r from-blue-500 to-indigo-700 py-16 px-6 text-white">
      <div className="container mx-auto text-center max-w-4xl">
        <h2 className="text-4xl font-extrabold leading-tight mb-6">
          Buy Global eSIMs
        </h2>
        <p className="text-lg md:text-xl mb-8 opacity-90">
          Say goodbye to the hassle of SIM cards while traveling! With ObicTrade, purchase eSIMs to connect seamlessly to global networks and stay connected no matter where you are.
        </p>
        <div className="bg-white shadow-2xl rounded-3xl p-8 mx-auto max-w-3xl text-left">
          <h3 className="text-2xl font-semibold text-blue-900 mb-6">How It Works:</h3>
          <ul className="list-disc list-inside text-gray-800 space-y-4">
            <li>
              <strong className="font-medium text-blue-700">Get in Touch on WhatsApp:</strong> Let us know your travel destination and data needs.
            </li>
            <li>
              <strong className="font-medium text-blue-700">Choose Your Plan:</strong> Select from our wide range of flexible and affordable eSIM plans.
            </li>
            <li>
              <strong className="font-medium text-blue-700">Activate Your eSIM:</strong> Follow the activation instructions sent to you via WhatsApp or email.
            </li>
          </ul>
          <p className="mt-6 text-lg text-gray-700">
            Enjoy uninterrupted connectivity anywhere in the world. Chat with us on{' '}
            <a
              href="https://wa.me/message/LOVDCRGL47Q5P1"
              target="_blank"
              rel="noopener noreferrer"
              className="text-orange-500 font-semibold underline hover:text-orange-600"
            >
              WhatsApp
            </a>{' '}
            to get started!
          </p>
        </div>
      </div>
    </section>
  );
};

export default BuyGlobalESIMs;
