import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import React from "react";

function AboutUs() {
  return (
    <section
      id="about-us"
      className="d-flex align-items-center"
      style={{
        backgroundColor: "#FFFFFF",
        padding: "5rem 1rem",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-6 d-flex align-items-center">
            <div>
              <p
                style={{
                  fontSize: "18px",
                  lineHeight: "1.8",
                  color: "#000000",
                }}
              >
                At ObicTrade, we specialize in buying and selling digital assets and gift cards, offering a secure and efficient platform that delivers maximum value for every trade.
                <br />
                <br />
                But we are more than just a trading platform. ObicTrade is a company with a purpose—dedicated to giving back to the community and making a difference in the lives of others.
                <br />
                 </p>
              {/* <div className="mt-4">
                <h5 className="font-italic" style={{ fontWeight: "600" }}>
                  michael & moyo
                </h5>
              </div> */}
            </div>
          </div>

          <div className="col-md-6 d-flex justify-content-center">
             <DotLottieReact
                src="/lottie/help.json"
                loop
                autoplay
              />
          </div>

        </div>
        
      </div>
    </section>
  );
}

export default AboutUs;
