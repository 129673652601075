import React from "react";
import { BsTwitterX } from "react-icons/bs";
import { FaFacebook, FaYoutube, FaInstagram, FaTiktok } from "react-icons/fa";

const Footer = () => {
  return (
    <footer id="footer" className="bg-white text-black">
      {/* Footer Top */}
      <div className="footer-top pb-10">
        <div className="container mx-auto px-6">
          <div className="flex flex-wrap -mx-4">
            {/* Obic Logo and Contact Info */}
            <div className="w-full sm:w-1/2 lg:w-1/4 px-4 mb-6">
              <img
                src="/obic.png"
                height="30"
                className="mt-1"
                alt="Obic Logo"
              />
              <p className="text-sm mt-4">
                Marble Hill Junction, Asaba, Delta State.
              </p>
              <p className="mt-2">
                <strong>obicsales@gmail.com</strong>
              </p>
              <a
                href="https://wa.me/message/LOVDCRGL47Q5P1"
                target="_blank"
                rel="noopener noreferrer"
                className="text-orange font-semibold"
              >
                Contact Us
              </a>

              {/* Social Media Links */}
              <div className="social-links mt-4 flex space-x-3">
                <a
                  href="https://www.tiktok.com/@obic_trade?_t=8rh3g2InNAN&_r=1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaTiktok className="text-orange text-2xl" />
                </a>
                <a
                  href="https://www.instagram.com/obictrade_?igsh=anEzZjQ5a2xiM2E3"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram className="text-orange text-2xl" />
                </a>
                <a
                  href="https://youtube.com/@obictrade?si=crwmpl5bHtd1-tHC"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaYoutube className="text-orange text-2xl" />
                </a>
                <a
                  href="https://x.com/obic_ltd?s=21&t=t89DSPHq321DDg-tBzfTQQ"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <BsTwitterX className="text-orange text-2xl" />
                </a>
                <a
                  href="https://facebook.com/ObicTrade"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebook className="text-orange text-2xl" />
                </a>
              </div>
            </div>

            {/* Links Section */}
            <div className="w-full sm:w-1/2 lg:w-3/4 flex flex-wrap px-4">
              <div className="w-full sm:w-1/2 lg:w-1/4 mb-6">
                <h4 className="font-semibold mb-3">Products</h4>
                <ul className="space-y-2">
                  <li>
                    <a href="/products#gift-cards" className="hover:text-orange">
                      Gift Cards
                    </a>
                  </li>
                  <li>
                    <a
                      href="/products#international-topup"
                      className="hover:text-orange"
                    >
                      International Lines
                    </a>
                  </li>
                  <li>
                    <a href="/products#esims" className="hover:text-orange">
                      Global eSIMs
                    </a>
                  </li>
                </ul>
              </div>

              <div className="w-full sm:w-1/2 lg:w-1/5 mb-6">
                <h4 className="font-semibold mb-3">Company</h4>
                <ul className="space-y-2">
                  <li>
                    <a href="/about" className="hover:text-orange">
                      About Us
                    </a>
                  </li>
                </ul>
              </div>

              <div className="w-full sm:w-1/2 lg:w-1/4 mb-6">
                <h4 className="font-semibold mb-3">Legal</h4>
                <ul className="space-y-2">
                  <li>
                    <a
                      href="/legal/terms"
                      className="hover:text-orange"
                    >
                      Terms & Conditions
                    </a>
                  </li>
                  <li>
                    <a
                      href="/legal/privacy"
                      className="hover:text-orange"
                    >
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a
                      href="/legal/antimoney"
                      className="hover:text-orange"
                    >
                      Anti-Money Laundering Policy
                    </a>
                  </li>
                </ul>
              </div>

              <div className="w-full sm:w-1/2 lg:w-1/4">
                <h4 className="font-semibold mb-3">Learn</h4>
                <ul className="space-y-2">
                  <li>
                    <a
                      href="https://obictrade.blogspot.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="hover:text-orange"
                    >
                      Blog
                    </a>
                  </li>
                  <li className="flex items-center">
                    Obic Campus Connect
                    <span className="bg-orange text-white text-xs text-center font-semibold px-2 py-1 rounded-full ml-2">
                      Coming Soon
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="footer-bottom text-center bg-black text-white py-4">
        &copy; {new Date().getFullYear()} <strong>ObicTrade</strong>. All Rights Reserved.
      </div>
    </footer>
  );
};

export default Footer;
