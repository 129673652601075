import React from 'react';

const Header = ({ title, subtitle }: any) => {
  return (
    <header
      className="bg-blue-900 text-white py-12 px-4 text-center pt-5"
      style={{
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className="container mx-auto mt-10 pt-8">
        <h1 className="text-4xl font-bold mb-2 pt-2">{title}</h1>
        {subtitle && <p className="text-lg font-light">{subtitle}</p>}
      </div>
    </header>
  );
};

export default Header;
