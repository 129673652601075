import React from 'react';

const DetailSection = () => {
  return (
    <section className="bg-deep-blue">
      <div className="text-center center-box p-5">
        <img src="/bg/products.png" height="150" alt="" />
        <h1 className="text-white"><b>Trade Gift Cards For Cash Instantly</b></h1>
        <h5 className='mt-5'>
          <small style={{ lineHeight: '125%', color: 'white' }}>
            ObicTrade allows you to safely and securely exchange your gift cards at high rates. Our rates are always updated everyday to make sure every gift card holder gets
            the best value on thier transactions.
          </small>
        </h5>

        <div className="mx-auto d-flex justify-content-center mt-8">
          <a
            href="https://wa.me/message/LOVDCRGL47Q5P1"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="bg-orange px-4 py-2 no-border rounded-lg text-white font-semibold">
              Get Started
            </button>
          </a>
        </div>
        {/* <div className="mt-5 col-md-5 mx-auto d-flex justify-content-center">
                <a
                  href="https://api.obictrade.com/download"
                  className="w-auto mx-2"
                >
                  <img src="/google-white.svg" width={150} alt="Google Play" />
                </a>
                <a
                  href="https://api.obictrade.com/download"
                  className="w-auto mx-2"
                >
                  <img src="/apple-white.svg" width={150} alt="Apple Store" />
                </a>
              </div> */}

      </div>
      <br />
    </section>
  );
};

export default DetailSection;
