import React from 'react';
import { Link } from 'react-router-dom'; // If using React Router for navigation

const GivingBackAd = () => {
  return (
    <div
      style={{
        backgroundColor: '#F7941D',
        color: '#ffffff',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
        marginTop: '20px',
        marginBottom: '20px',
      }}
    >
      <h3 style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
        Help Us Make a Difference!
      </h3>
      <p style={{ fontSize: '1rem', marginBottom: '15px' }}>
        Every successful trade at ObicTrade contributes to initiatives that uplift lives. Learn more about how we're giving back to the community!
      </p>
      <Link
        to="/giving-back"
        style={{
          fontSize: '1.1rem',
          color: '#0b0d33',
          textDecoration: 'none',
          fontWeight: 'bold',
          border: '2px solid #0b0d33',
          padding: '10px 20px',
          borderRadius: '5px',
          transition: 'background-color 0.3s ease',
        }}
      >
        Learn More
      </Link>
    </div>
  );
};

export default GivingBackAd;
