import React from 'react';
import Header from '../../components/Header';

const PrivacyPolicy = () => {
  const userInfoDetails = [
    "Contact information: your name, phone number, email address, date of birth, and other similar information.",
    "Financial information: Your full bank account and/or debit card details that you input when you use Obictrade.com Services.",
    "Account activity: Information about your transactions, activities on the site, and device details to prevent fraud and secure your account."
  ];

  const cookieDetails = [
    "Recognize you as an ObicTrade user and manage the signup process.",
    "Remember your login status to prevent repeated logins.",
    "Store preferences when interacting with the site.",
    "Use Flash cookies for account security and fraud detection.",
    "Collect device information to mitigate risks and promote trust."
  ];

  const personalInfoUsage = [
    "Provide ObicTrade Services and customer support you request.",
    "Process transactions and send notices about your transactions.",
    "Resolve disputes, collect fees, and troubleshoot problems.",
    "Prevent and investigate prohibited activities or violations.",
    "Customize and improve ObicTrade Services and site content.",
    "Deliver targeted marketing and promotional offers.",
    "Verify your identity against third-party databases."
  ];

  return (
    <div>
     <Header
        title="Privacy Policy"
      />
      <div className="mt-5 container text-center">
      <section>
        <h3>1. Information We Collect</h3>
        <p>
          At ObicTrade, we gather information to provide and enhance our services. This includes personal details you provide when using our site, along with automatically collected information.
        </p>
        <ul>
          {userInfoDetails.map((detail, index) => (
            <li key={index}>{detail}</li>
          ))}
        </ul>
        <p>
          We also collect data related to your transactions, activities, and device information for fraud prevention and account monitoring purposes.
        </p>
      </section>
      <br />

      <section>
        <h3>2. How We Use Your Information</h3>
        <ul>
          {personalInfoUsage.map((usage, index) => (
            <li key={index}>{usage}</li>
          ))}
        </ul>
        <p>
          We do not use your personal information for any purpose other than what is disclosed in this policy unless we have your consent.
        </p>
      </section>
      <br />

      <section>
        <h3>3. Cookie Policy</h3>
        <h6>
          Cookies are small files stored on your device to enhance your browsing experience. Below is how we use them.
        </h6>
        <ul>
          {cookieDetails.map((detail, index) => (
            <li key={index}>{detail}</li>
          ))}
        </ul>
        <p>
          You can control cookie settings through your browser. However, disabling cookies may limit certain features on our site.
        </p>
      </section>
      <br />

      <section>
        <h3>4. Data Security</h3>
        <p>
          We implement advanced security measures to protect your personal data, including encryption and strict access controls. Payment information is handled by PCI DSS-compliant providers.
        </p>
      </section>
      <br />

      <section>
        <h3>5. Your Rights</h3>
        <p>
          You have the right to access, correct, or request the deletion of your personal data. You can also opt-out of marketing communications. Please contact our support team for assistance.
        </p>
      </section>
      <br />

      <section>
        <h3>6. External Links</h3>
        <p>
          Our website may contain links to third-party sites. We are not responsible for their privacy practices or content. Please review their policies independently.
        </p>
      </section>
      <br />

      <section>
        <h3>7. Data Retention Policy</h3>
        <p>
          We retain your personal data only as long as necessary for legal, operational, or dispute resolution purposes, typically for a minimum of five years.
        </p>
      </section>
      <br />

      <section>
        <h3>8. Contact Us</h3>
        <p>
          If you have any questions or concerns regarding this Privacy Policy, please reach out to us:
        </p>
        <p>
          <strong>Email:</strong> obicsales@gmail.com
          <br />
          <strong>Phone:</strong> +2347068843470
        </p>
      </section>
      <br />

      <footer>
        <p><strong>Last Updated:</strong> April 28, 2024</p>
      </footer>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
