import React from 'react';
import { Link } from 'react-router-dom'; // If using React Router for navigation

const GivingBackDetails = () => {
  return (
    <div style={{ padding: '50px', backgroundColor: '#f7f7f7' }} className='pt-5'>
      <section
        style={{
          backgroundColor: '#0b0d33',
          color: '#ffffff',
          padding: '30px 20px',
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        }}
      >
        <h1 style={{ fontSize: '2.5rem', fontWeight: 'bold' }}>Giving Back to the Community</h1>
        <p style={{ fontSize: '1.25rem', marginTop: '20px' }}>
          At ObicTrade, we are deeply committed to supporting the less privileged and improving lives in our community.
          With every successful trade, we contribute to initiatives that uplift lives, including:
        </p>

        <ul style={{ marginTop: '30px', fontSize: '1.2rem', listStyleType: 'disc', paddingLeft: '20px' }}>
          <li>Orphanage Visits: Supporting children in orphanages with donations and care.</li>
          <li>Feeding the Streets Projects: Providing meals and essentials to those in need.</li>
          <li>School Engagements: Empowering students through educational programs and outreach.</li>
        </ul>

        <p style={{ marginTop: '20px', fontSize: '1.2rem' }}>
          When you choose ObicTrade, you’re not just securing the best deals—you’re also helping to make a difference.
        </p>
      </section>

      <div style={{ marginTop: '30px', textAlign: 'center' }}>
        <Link
          to="/"
          style={{
            fontSize: '1.2rem',
            color: '#F7941D',
            textDecoration: 'none',
            fontWeight: 'bold',
          }}
        >
          Back to Home
        </Link>
      </div>
    </div>
  );
};

export default GivingBackDetails;
