import React from 'react';
import { useParams } from 'react-router-dom';
import { Error404 } from '../../components/error/Error404';
import PrivacyPolicy from './PrivacyPolicy';
import TermsAndConditions from './Terms';
import AntiMoneyLaunderingPolicy from './AMLPolicy';
import Banner from '../../components/Banner';
import Footer from '../../components/Footer';
const Legal = () => {
    const { legal } = useParams(); // Assuming you have a path parameter named 'pathParam'

    let componentToRender;
  
    switch (legal) {
      case 'privacy':
        componentToRender = <PrivacyPolicy />;
        break;
      case 'terms':
        componentToRender = <TermsAndConditions />;
        break;
      case 'antimoney':
        componentToRender = <AntiMoneyLaunderingPolicy />;
        break;
      default:
        componentToRender = <Error404 />;
    }
  
    return (
        <>
        <Banner/>
        {componentToRender}
        <Footer/>
        </>);
  
};

export default Legal;
