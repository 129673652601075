import React from 'react';
import { FaGift, FaGlobe, FaSimCard } from 'react-icons/fa';
import { Link } from 'react-router-dom'; // Ensure React Router is installed and set up in your project

function DoMoreWithObic() {
  return (
    <section className="bg-white text-gray-800 py-12 px-4">
      <div className="container mx-auto">
        {/* Title and Description */}
        <div className="text-center mb-10">
          <h2 className="text-4xl font-bold mb-4">Do More Obic</h2>
          <p className="text-lg text-gray-500">
            Simplify your digital needs with ObicTrade. From gift cards to eSIMs and international top-ups, we make it all fast, reliable, and secure.
          </p>
        </div>

        {/* Feature Cards */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-10">
          {/* Card 1 - Gift Cards */}
          <Link
            to="/products#gift-cards"
            className="flex items-center p-6 bg-gray-50 rounded-lg shadow-md hover:shadow-lg hover:bg-gray-100 transition no-underline"
            style={{ textDecoration: 'none' }}
          >
            <div className="p-4 bg-yellow-100 rounded-full">
              <FaGift className="text-yellow-500 text-2xl" />
            </div>
            <div className="ml-4">
              <h3 className="font-semibold text-lg">Buy Gift Cards</h3>
              <p className="text-sm text-gray-600">
                Seamlessly buy and send gift cards with competitive rates and instant delivery via WhatsApp.
              </p>
            </div>
          </Link>

          {/* Card 2 - International Top-Up */}
          <Link
            to="/products#international-topup"
            className="flex items-center p-6 bg-gray-50 rounded-lg shadow-md hover:shadow-lg hover:bg-gray-100 transition no-underline"
            style={{ textDecoration: 'none' }}
          >
            <div className="p-4 bg-yellow-100 rounded-full">
              <FaGlobe className="text-yellow-500 text-2xl" />
            </div>
            <div className="ml-4">
              <h3 className="font-semibold text-lg">Top Up Your Lines</h3>
              <p className="text-sm text-gray-600">
                Stay connected globally with secure and instant international line top-up services.
              </p>
            </div>
          </Link>

          {/* Card 3 - Buy eSIMs */}
          <Link
            to="/products#esims"
            className="flex items-center p-6 bg-gray-50 rounded-lg shadow-md hover:shadow-lg hover:bg-gray-100 transition no-underline"
            style={{ textDecoration: 'none' }}
          >
            <div className="p-4 bg-yellow-100 rounded-full">
              <FaSimCard className="text-yellow-500 text-2xl" />
            </div>
            <div className="ml-4">
              <h3 className="font-semibold text-lg">Buy Global eSIMs</h3>
              <p className="text-sm text-gray-600">
                Enjoy hassle-free travel with affordable eSIM plans and uninterrupted global connectivity.
              </p>
            </div>
          </Link>
        </div>
      </div>
    </section>
  );
}

export default DoMoreWithObic;
