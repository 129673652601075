import GivingBackDetails from './givebackDetails';
import Banner from '../../components/Banner';

const GivingBackPage = () => {
    return (
        <>
            <Banner />
            <br/>
            <br/>
            <br/>
            <GivingBackDetails />
        </>
    );
};

export default GivingBackPage;
