import Banner from "../../components/Banner";
import Footer from "../../components/Footer";
import DetailSection from "./Detail";
import DoMore from "./DoMore";
import Hero from "./hero";

function HomePage() {
    return (
      <div>
        <Banner/>
        <Hero/>
        <DoMore/>
        <DetailSection/>
        <Footer/>
    </div>
    )
}

export default HomePage;