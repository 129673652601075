import React from 'react';

const OurTeam = () => {
  return (
    <div
      style={{
        backgroundColor: '#f7f7f7',
        padding: '4rem 2rem',
        textAlign: 'center',
        borderRadius: '12px',
        boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)',
      }}
    >
      <h2
        style={{
          fontSize: '3rem',
          fontWeight: 'bold',
          color: '#0b0d33',
          marginBottom: '2rem',
          letterSpacing: '1.5px',
        }}
      >
        Meet Our Team
      </h2>

      <p
        style={{
          fontSize: '1.2rem',
          color: '#333',
          maxWidth: '700px',
          margin: '0 auto 3rem',
          fontStyle: 'italic',
          lineHeight: '1.8',
        }}
      >
        Our team at ObicTrade comprises passionate and experienced professionals, working together to provide you with a trusted platform while positively impacting the community.
      </p>

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(280px, 1fr))',
          gap: '2rem',
          justifyItems: 'center',
        }}
      >
        {[
          {
            title: 'Visionaries',
            description:
              'Innovating to keep us at the forefront of the digital trading space, our visionaries drive us to new heights.',
          },
          {
            title: 'Support Experts',
            description:
              'Our dedicated support experts provide exceptional customer service every step of the way, ensuring your experience is seamless.',
          },
          {
            title: 'Tech Specialists',
            description:
              'Leveraging cutting-edge technology to ensure secure and seamless transactions, our tech specialists keep us ahead of the curve.',
          },
        ].map((item, index) => (
          <div
            key={index}
            style={{
              backgroundColor: '#ffffff',
              padding: '2rem',
              borderRadius: '12px',
              boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)',
              transition: 'transform 0.3s ease, box-shadow 0.3s ease',
              cursor: 'pointer',
              textAlign: 'center',
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.transform = 'scale(1.05)';
              e.currentTarget.style.boxShadow = '0 10px 20px rgba(0, 0, 0, 0.15)';
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.transform = 'scale(1)';
              e.currentTarget.style.boxShadow = '0 6px 12px rgba(0, 0, 0, 0.1)';
            }}
          >
            <h3
              style={{
                fontSize: '1.8rem',
                color: '#0b0d33',
                marginBottom: '1rem',
                fontWeight: 'bold',
              }}
            >
              {item.title}
            </h3>
            <p
              style={{
                fontSize: '1rem',
                color: '#555',
                lineHeight: '1.6',
              }}
            >
              {item.description}
            </p>
          </div>
        ))}
      </div>

      <div
        style={{
          marginTop: '3rem',
          backgroundColor: '#0b0d33',
          padding: '2rem',
          borderRadius: '12px',
          color: '#ffffff',
        }}
      >
        <h3
          style={{
            fontSize: '1.8rem',
            fontWeight: 'bold',
            marginBottom: '1rem',
          }}
        >
          Together, We Make a Difference
        </h3>
        <p
          style={{
            fontSize: '1.2rem',
            fontStyle: 'italic',
            lineHeight: '1.8',
          }}
        >
          Our team is united by a shared vision to make ObicTrade not just a platform for digital trading, but a force for good in the community.
        </p>
      </div>
    </div>
  );
};

export default OurTeam;
