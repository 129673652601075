import React from 'react';

const OurServices = () => {
  const services = [
    {
      title: 'Gift Card Exchange',
      description:
        'Trade your unused gift cards for cash or other digital assets at competitive rates.',
      icon: '🎁', // Placeholder for an icon or image
    },
    {
      title: 'Digital Asset Trading',
      description:
        'Buy and sell digital currencies and other digital goods with confidence and ease.',
      icon: '💰',
    },
    {
      title: 'Instant Transactions',
      description:
        'Enjoy quick and seamless transactions, saving you time and hassle.',
      icon: '⚡',
    },
    {
      title: 'Secure Platform',
      description:
        'Your security is our priority. All transactions are conducted with top-notch encryption and robust fraud protection measures.',
      icon: '🔒',
    },
  ];

  return (
    <section
      id="our-services"
      style={{
        backgroundColor: '#F8F8F8',
        padding: '3rem 1rem',
      }}
    >
      <div className="container">
        <div className="text-center mb-5">
          <h2 style={{ fontSize: '2.5rem', fontWeight: 'bold', color: '#333' }}>
            Our Services
          </h2>
          <p
            style={{
              fontSize: '1.1rem',
              color: '#666',
              lineHeight: '1.8',
              maxWidth: '800px',
              margin: '0 auto',
            }}
          >
            We offer a wide range of services designed to meet your digital
            trading needs.
          </p>
        </div>

        <div className="row">
          {services.map((service, index) => (
            <div
              key={index}
              className="col-lg-6 col-md-6 col-sm-12 mb-4"
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '1rem',
                backgroundColor: '#FFFFFF',
                borderRadius: '10px',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              }}
            >
              <div
                style={{
                  fontSize: '2.5rem',
                  marginRight: '1rem',
                  color: '#F7941D',
                }}
              >
                {service.icon}
              </div>
              <div>
                <h4
                  style={{
                    color: '#F7941D',
                    fontWeight: 'bold',
                    fontSize: '1.5rem',
                  }}
                >
                  {service.title}
                </h4>
                <p style={{ fontSize: '1rem', color: '#555', lineHeight: '1.8' }}>
                  {service.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default OurServices;
