import Banner from "../../components/Banner";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import GiftCards from "./giftcards";
import BuyGlobalESIMs from "./globalESims";
import InternationalLine from "./internationalLine";

function ProductsPage() {
    return (
        <div>
            <Banner />
            <Header
                title="Our Products"
                subtitle="Explore a range of offerings tailored to meet your needs."
            />
            <div id="gift-cards">
                <GiftCards />
            </div>

            <div id="international-topup">
                <InternationalLine />
            </div>

            <div id="esims">
                <BuyGlobalESIMs />
            </div>
            <Footer />
        </div>
    )
}

export default ProductsPage;