import Banner from "../../components/Banner";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import GivingBackAd from "../giveback/givebackAd";
import AboutUs from "./AboutUs";
import OurServices from "./OurServices";
import Payment from "./PaymentOptions";
import OurTeam from "./Team";
function AboutPage() {
  return (
    <div>
      <Banner />
      <Header
        title="Our Company"
        subtitle="Your Trusted Partner for Growth"
      />
      <div className="pt-12 container">
        <AboutUs />
        <OurServices />
        <OurTeam />
        <div className="container">
          <GivingBackAd />
        </div>
      </div>
      <Payment />
      <Footer />
    </div>
  )
}

export default AboutPage;