import React from "react";
import { motion } from "framer-motion";
import { FaWhatsapp } from "react-icons/fa";

const GiftCards = () => {
  return (
    <motion.section
      className="section-container bg-gradient-to-b from-gray-100 to-white"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6, ease: "easeOut" }}
    >
      <section className="container mx-auto px-6 py-12 lg:py-20">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          
          {/* Left Section: Image and Description */}
          <div className="flex flex-col items-center lg:items-start text-center lg:text-left">
            <img
              src="/bg/products.png"
              alt="Buy Gift Cards"
              className="w-full max-w-md mb-8 m-auto"
            />
            <br/>
            <h3 className="text-3xl m-auto md:text-4xl font-bold text-gray-800 mb-4">
              Buy Gift Cards
            </h3>
            <p className="text-lg text-gray-600">
              At ObicTrade, we make buying gift cards seamless and reliable.
              Whether you're purchasing for yourself or sending a thoughtful
              gift to a loved one, we've got you covered.
            </p>
          </div>
          
          {/* Right Section: Steps and Features */}
          <div className="bg-white shadow-lg rounded-lg p-6 lg:p-10 space-y-8">
            
            {/* Steps Section */}
            <div>
              <h2 className="text-2xl font-semibold text-gray-800 mb-4">
                How It Works
              </h2>
              <ol className="list-decimal list-inside text-gray-600 space-y-3">
                <li>
                  <strong>Browse Gift Cards:</strong> Contact us on{" "}
                  <a
                    href="https://wa.me/your-whatsapp-link"
                    className="text-blue-600 underline hover:text-blue-800"
                  >
                    WhatsApp
                  </a>{" "}
                  to explore a variety of gift cards.
                </li>
                <li>
                  <strong>Quick Payment Options:</strong> Securely pay via your
                  preferred method.
                </li>
                <li>
                  <strong>Fast Delivery:</strong> Get your gift card details
                  instantly via WhatsApp or email.
                </li>
              </ol>
            </div>

            {/* Features Section */}
            <div>
              <h2 className="text-2xl font-semibold text-gray-800 mb-4">
                Why Choose Us?
              </h2>
              <ul className="list-disc list-inside text-gray-600 space-y-2">
                <li>🚀 Competitive rates.</li>
                <li>🔒 Fast and secure transactions.</li>
                <li>📞 24/7 support via WhatsApp.</li>
              </ul>
            </div>

            {/* Call-to-Action Button */}
            <motion.a
              href="https://wa.me/your-whatsapp-link"
              className="inline-flex items-center justify-center px-6 py-3 text-lg font-semibold text-white bg-green-500 rounded-lg shadow hover:bg-green-600 transition"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <FaWhatsapp className="mr-3 text-xl" />
              Connect with us on WhatsApp
            </motion.a>
          </div>
        </div>
      </section>
    </motion.section>
  );
};

export default GiftCards;
