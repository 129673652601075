import { useState } from 'react';
import { Dialog, Disclosure } from '@headlessui/react';
import { CiMenuBurger } from 'react-icons/ci';
import { IoMdClose } from 'react-icons/io';
import { Link } from 'react-router-dom';

function MobileMenu({ setMobileMenuOpen }:any) {
  return (
    <>
      <Dialog.Panel className="fixed inset-y-0 right-0 z-[1000] w-full overflow-y-auto bg-deep-blue px-6 py-6 sm:max-w-sm">
        <div className="flex items-center justify-between">
          <a href="/" className="-m-1.5 p-1.5">
            <img src="/obic.png" alt="Obic Logo" className="w-32 h-8" />
          </a>
          <button
            type="button"
            className="-m-2.5 rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(false)}
          >
            <IoMdClose className="h-6 w-6 text-white" aria-hidden="true" />
          </button>
        </div>
        <div className="mt-6 flow-root">
          <div className="-my-6 divide-y divide-gray-500/10">
            <div className="space-y-2 py-6">
              <Link to="/" className="block my-3 text-white">Home</Link>
              <Link to="/about" className="block my-3 text-white">Company</Link>
              <a
                href="https://obictrade.blogspot.com/"
                className="block my-3 text-white"
                target="_blank"
                rel="noopener noreferrer"
              >
                Blog
              </a>
              <Link to="/faq" className="block my-3 text-white">FAQ</Link>
              <Disclosure as="div" className="-mx-3">
                <a
                  href="https://wa.link/mucufa"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="bg-orange px-4 py-2 my-8 rounded-lg text-white font-semibold">
                    Trade with agent
                  </button>
                </a>
              </Disclosure>
            </div>
          </div>
        </div>
      </Dialog.Panel>
    </>
  );
}

function Banner() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <>
      <header className="bg-deep-blue fixed w-full z-10">
        <nav
          className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
          aria-label="Global"
        >
          <div className="flex lg:flex-1">
            <a href="/" className="p-1.5">
              <img src="/obic.png" alt="Obic Logo" className="w-32 h-8" />
            </a>
          </div>
          <div className="hidden lg:flex lg:justify-start">
            <Link to="/" className="mx-10 text-white">Home</Link>
            <Link to="/about" className="mx-10 text-white">Company</Link>
            <a
              href="https://obictrade.blogspot.com/"
              className="mx-10 text-white"
              target="_blank"
              rel="noopener noreferrer"
            >
              Blog
            </a>
            <Link to="/faq" className="mx-10 text-white">FAQ</Link>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="inline-flex items-center justify-center rounded-md p-2.5"
              onClick={() => setMobileMenuOpen(true)}
            >
              <CiMenuBurger className="h-6 w-6 text-white" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <a
              href="https://wa.me/message/LOVDCRGL47Q5P1"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="bg-orange px-4 py-2 rounded-lg text-white font-semibold">
                Trade with agent
              </button>
            </a>
          </div>
        </nav>
        <Dialog
          as="div"
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-50 bg-black bg-opacity-50" />
          <MobileMenu setMobileMenuOpen={setMobileMenuOpen} />
        </Dialog>
      </header>
    </>
  );
}

export default Banner;
