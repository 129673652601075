import { createBrowserRouter,  } from 'react-router-dom'
import { Error404 } from './components/error/Error404'
import LandingPage from './pages/landing_page'
import Legal from './pages/legal'
import FaqSection from './pages/faqs'
import AboutPage from './pages/about'
import GivingBackPage from './pages/giveback'
import ProductsPage from './pages/products'

const routes = (user: any) => {
  return createBrowserRouter([
    {
      path: '/',
      element: <LandingPage />,
    },
    {
      path: '/products',
      element: <ProductsPage />,
    },
    {
      path: '/legal/:legal',
      element: <Legal />,
    },
    {
      path: '/about',
      element: <AboutPage />,
    },
    {
      path: '/giving-back',
      element: <GivingBackPage />,
    },
    {
      path: '/faq',
      element: <FaqSection />,
    },
    {
      path: '*',
      element: <Error404 />,
    },
  ])
}

export default routes
