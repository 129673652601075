import React from 'react';
import Header from '../../components/Header';

const TermsAndConditions = () => {
  const userObligations = [
    "You agree to provide accurate and complete information when creating an account.",
    "You will not engage in any activities that violate the law or these terms.",
    "You must maintain the confidentiality of your account credentials.",
    "You agree to notify us immediately if your account is compromised.",
  ];

  const prohibitedActivities = [
    "Engaging in fraudulent or deceptive activities.",
    "Uploading or transmitting harmful content, including viruses and malware.",
    "Attempting to access our systems or services without authorization.",
    "Violating intellectual property rights or others' privacy rights.",
  ];

  const serviceUsage = [
    "You may only use the services for lawful purposes and in accordance with these terms.",
    "You may not copy, modify, distribute, or reverse-engineer the service.",
    "You may not engage in any activity that interferes with or disrupts the service.",
    "We reserve the right to suspend or terminate your account for violations.",
  ];

  const liabilityAndDisclaimers = [
    "We provide the service ‘as-is’ and make no warranties regarding availability or functionality.",
    "We are not liable for any indirect, incidental, or consequential damages resulting from your use of the service.",
    "You assume all risks associated with the use of our service, including the risk of data loss.",
  ];

  const terminationConditions = [
    "We may suspend or terminate your access if you breach these terms.",
    "You may terminate your account at any time by contacting our support team.",
    "Upon termination, you must cease using our services and remove any content you may have uploaded.",
  ];

  return (
    <>
    <Header
      title="Terms & Conditions"
    />
    <div className="mt-5 container text-center">
      <section>
        <h3>User Obligations</h3>
        <p>
          By using ObicTrade, you agree to the following obligations to ensure a safe and legal experience:
        </p>
        <ul>
          {userObligations.map((obligation, index) => (
            <li key={index}>{obligation}</li>
          ))}
        </ul>
      </section>
      <br />

      <section>
        <h3>Prohibited Activities</h3>
        <p>
          To maintain a secure environment, we prohibit the following activities on ObicTrade:
        </p>
        <ul>
          {prohibitedActivities.map((activity, index) => (
            <li key={index}>{activity}</li>
          ))}
        </ul>
      </section>
      <br />

      <section>
        <h3>Service Usage</h3>
        <p>
          You are permitted to use ObicTrade services only under the following conditions:
        </p>
        <ul>
          {serviceUsage.map((usage, index) => (
            <li key={index}>{usage}</li>
          ))}
        </ul>
      </section>
      <br />

      <section>
        <h3>Liability and Disclaimers</h3>
        <p>
          Please read the following disclaimers regarding our liability:
        </p>
        <ul>
          {liabilityAndDisclaimers.map((disclaimer, index) => (
            <li key={index}>{disclaimer}</li>
          ))}
        </ul>
      </section>
      <br />

      <section>
        <h3>Termination of Service</h3>
        <p>
          The following conditions apply for terminating your access to ObicTrade services:
        </p>
        <ul>
          {terminationConditions.map((condition, index) => (
            <li key={index}>{condition}</li>
          ))}
        </ul>
      </section>
      <br />

      <footer>
        <p><strong>Last Updated:</strong> April 28, 2024</p>
      </footer>
    </div>
    </>
  );
};

export default TermsAndConditions;
